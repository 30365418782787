import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <h1>NOT FOUND</h1>
      <p>
        We&apos;re really sad but that page doesn&apos;t exist... but if you seek, you shall find.
      </p>

      <p>
        Please try the <Link to="/">Homepage</Link>.
      </p>
      <Link className="btn btn-primary" to="/">
        Homepage
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
